import Axios from "./caller.service";

const getMyAlertes = () => {
	return Axios.get("/api/alerte/mes-alertes");
};

const postChangeStatut = (id, credentials) => {
	return Axios.post("/api/alerte/change/" + id, credentials);
};

const getVehiculeAlerte = (id) => {
	return Axios.get("/api/alerte/vehicule/" + id);
};

const getAlerteByStatut = () => {
	return Axios.get("/api/alerte/statut/1");
};

const getAlerteInfo = (id) => {
	return Axios.get("/api/alerte/info/" + id);
};

export const alerteService = {
	getMyAlertes,
	postChangeStatut,
	getVehiculeAlerte,
	getAlerteByStatut,
	getAlerteInfo,
};
