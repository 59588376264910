import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { vehiculeService } from "../../_services/vehicule.service";
import NavBar from "../../components/NavBar";

const VehiculeAdd = () => {
	const navigate = useNavigate();

	const [credentials, setCredentials] = useState({
		plaque: "",
		marque: "",
		model: "",
		annee: "",
		kilometrage: "",
		ad_blue: false,
	});

	const onChange = (e) => {
		setCredentials({
			...credentials,
			[e.target.name]: e.target.value,
		});
	};

	const onSubmit = (e) => {
		e.preventDefault();

		vehiculeService
			.postAddVehicule(credentials)
			.then((res) => {
				console.log(res);
				navigate("/vehicule/list");
			}).catch((error) => console.log(error.message));
	};

	return (
		<div className="global">
			<NavBar />

			<section className="grey vehiculeadd-container">
				<h2>Ajout d'un véhicule </h2>

				<form>
					<div className="vehiculeadd-container__inputs">
						<div className="input-container">
							<label htmlFor="plaque">Plaque</label>
							<input type="text" name="plaque" value={credentials.plaque} onChange={onChange} />
						</div>

						<div className="input-container">
							<label htmlFor="marque">Marque</label>
							<input type="text" name="marque" value={credentials.marque} onChange={onChange} />
						</div>

						<div className="input-container">
							<label htmlFor="model">Modèle</label>
							<input type="text" name="model" value={credentials.model} onChange={onChange} />
						</div>

						<div className="input-container">
							<label htmlFor="annee">Année</label>
							<input type="text" name="annee" value={credentials.annee} onChange={onChange} />
						</div>

						<div className="input-container">
							<label htmlFor="kilometrage">Kilométrage</label>
							<input type="text" name="kilometrage" value={credentials.kilometrage} onChange={onChange} />
						</div>

						<div className="input-container">
							<label htmlFor="ad_blue">AdBlue</label>
							<input type="checkbox" name="ad_blue" value={credentials.ad_blue} checked={credentials.ad_blue} onChange={(e) =>
								setCredentials({
									...credentials,
									ad_blue: e.target.checked,
								})
							} />
						</div>
					</div>
					
					<input className="button" type="submit" value="Ajouter" onClick={onSubmit} />
				</form>
			</section>
		</div>
	);
};

export default VehiculeAdd;