import Axios from "./caller.service";

const getMyVehicule = () => {
	return Axios.get("/api/vehicule/garage");
};

const getVehiculeInfo = (vid) => {
	return Axios.get("/api/vehicule/" + vid);
};

const postAddVehicule = (credentials) => {
	return Axios.post("/api/vehicule/nouveau", credentials);
};

const postEditVehicule = (credentials, vid) => {
	return Axios.post("/api/vehicule/" + vid + "/edit", credentials);
};

const postDeleteVehicule = (vid) => {
	return Axios.post(`/api/vehicule/delete/${vid}`);
};

const getMontantFacture = (vid) => {
	return Axios.get(`/api/facture/montant/${vid}`);
};

const getMontantTotalFacture = () => {
	return Axios.get(`/api/facture/montant-facture-mensuelle`);
};

const getDepenseTotalMensuel = () => {
	return Axios.get(`/api/facture/montant-total/mensuel`);
};

const getDepenseTotal = () => {
	return Axios.get(`/api/facture/montant-total`);
};

const getPleinTotal = () => {
	return Axios.get("/api/facture/montant-total-pleins");
};

const getPleinTotalMensuel = () => {
	return Axios.get("/api/facture/montant-total-pleins/mensuel");
};

const getMontantTotalMensuelVehicule = (id) => {
	return Axios.get("/api/facture/montant-total/mensuel/" + id);
};

const getMontantTotalVehicule = (id) => {
	return Axios.get("/api/facture/montant-total/" + id);
};

const postChangeKilo = (vid, credentials, newKilo) => {
	return Axios.post(
		`/api/vehicule/kilometrage/${vid}`,
		{ kilometrage: newKilo },
		credentials
	);
};

const vehiculesPlaqueSearch = (credentials) => {
	return Axios.post(`/api/vehicule/plaque/search/${credentials}`);
};

export const vehiculeService = {
	getMyVehicule,
	getVehiculeInfo,
	postAddVehicule,
	postEditVehicule,
	postDeleteVehicule,
	getMontantFacture,
	getMontantTotalFacture,
	postChangeKilo,
	getDepenseTotalMensuel,
	getDepenseTotal,
	getPleinTotal,
	getPleinTotalMensuel,
	getMontantTotalMensuelVehicule,
	getMontantTotalVehicule,
	vehiculesPlaqueSearch,
};