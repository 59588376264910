import { useNavigate } from 'react-router-dom';

import "../styles/components/_authbanner.scss";

const AuthBanner = () => {
	const navigate = useNavigate();

	return (
		<div className="auth-form__banner">
			<img src='/icone/AutoMilesBlanc.png' alt='logo' className='auth-form__logo' onClick={() => navigate('/')} onDragStart={(e) => { e.preventDefault() }} />
			<img src="/images/login_banner.jpg" onDragStart={(e) => { e.preventDefault() }} />

			<div className="text-container">
				<h3>Bon retour !<br/>Connectez-vous avec votre compte AutoMiles</h3>
				<p>Avec AutoMiles, maîtrisez le budget de chacun de vos véhicule pour veiller à l'ensemble de vos dépenses. AutoMiles peut aussi bien accompagner les </p>
			</div>
		</div>
	);
};

export default AuthBanner;