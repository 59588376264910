import Axios from "./caller.service";

const getMaintenance = () => {
	return Axios.get("/api/maintenance/liste");
};

const postEditMaintenance = (id, credentials) => {
	return Axios.post("/api/maintenance/" + id + "/edit", credentials);
};

const postAjoutMaintenance = (credentials) => {
	return Axios.post("/api/maintenance/ajout", credentials);
};

const getMaintenanceInfo = (id) => {
	return Axios.get("/api/maintenance/" + id + "/info");
};

export const AdminService = {
	getMaintenance,
	postEditMaintenance,
	postAjoutMaintenance,
	getMaintenanceInfo,
};
