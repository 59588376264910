import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

const FactureNav = () => {
    const navigate = useNavigate()

    const onAccueil = () => {
        navigate('/')
    }
    return (
        <div className="facture-nav">
            {/* <img src='/icone/AutoMiles.png' alt='logo' className='img-logo' onClick={onAccueil}/> */}

            <ul>
                <NavLink to="/">
                    <li >Accueil</li>
                </NavLink>
                <NavLink to="/facture/historique">
                    <li>Factures</li>
                </NavLink>
                <NavLink to="/facture/">
                    <li >Ajouter</li>
                </NavLink>
                <NavLink to="/facture/vehicule">
                    <li>Véhicules</li>
                </NavLink>
            </ul>
        </div>
    );
};

export default FactureNav;