import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { vehiculeService } from "../../_services/vehicule.service";

import NavBar from "../../components/NavBar";
import FactureNav from "../../components/FactureNav";

const FactureHome = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [vehicules, setVehicules] = useState([]);

	// Définissez une fonction handleClick pour récupérer la valeur de la div cliquée
	const handleClick = (id) => {
		console.log(id);
		navigate("/facture/add/" + id);
	};

	useEffect(() => {
		vehiculeService
			.getMyVehicule()
			.then((res) => {
				setVehicules(res.data.vehicules);
				setLoading(false);

				console.log(res.data.vehicules);
			})
			.catch((err) => console.log(err));
	}, []);

	const handleBack = () => {
		navigate(-1);
	};

	if (loading) {
		return (
			<div className="contain-loader">
				<div className="loader">
					<div className="📦"></div>
					<div className="📦"></div>
					<div className="📦"></div>
					<div className="📦"></div>
					<div className="📦"></div>
				</div>
			</div>
		);
	}

	return (
		<div className="global">
			<NavBar />

			<section className="grey facture-container">
				<div className="Vehicule">
					<FactureNav />

					{/*<p>
						<img
							className="retour"
							onClick={handleBack}
							src="../../../icone/backIcon.png"
							alt="icone de retour"
						/>
					</p>*/} 
					<h3>Garage</h3>

					<section className="sectionDivFacture">
						<table className="tableau-facture">
							<tbody className="tableau-facture">
								<tr className="data-facture">
									<td>
										<strong>Plaque</strong>
									</td>
									<td className="hidden-titre">
										<strong>Marque</strong>
									</td>
									<td>
										<strong>Model</strong>
									</td>
									<td>
										<strong>Année</strong>
									</td>
									<td className="hidden-titre">
										<strong>Kilométrage</strong>
									</td>
									<td className="hidden-mobile-bouton">
										<strong>Choisir</strong>
									</td>
								</tr>

								{vehicules.map((vehicule, index) => (
									<tr
										onClick={() => handleClick(vehicule.id)}
										key={vehicule.id}
										value={vehicule.id}
										className="hidden-data-facture"
										style={{
											backgroundColor:
												index % 2 === 0
													? "#FFFFFF"
													: "#F5F5F5",
										}}
									>
										<td className="pAlertePerso">
											{vehicule.plaque.length > 5
												? vehicule.plaque.substring(
														0,
														5
												  ) + "..."
												: vehicule.plaque}
										</td>
										<td className="hidden-mobile-bouton">
											{vehicule.marque}
										</td>
										<td className="">{vehicule.model}</td>
										<td className="">{vehicule.annee}</td>
										<td className="hidden-mobile-bouton">
											{vehicule.kilometrage}
										</td>
										<td className="hidden-mobile-bouton">
											<button
												className="boutonFiltreFacture1"
												onClick={() =>
													handleClick(vehicule.id)
												}
											>
												Choisir
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</section>
				</div>
			</section>
		</div>
	);
};

export default FactureHome;
