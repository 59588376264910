import React from 'react';

const NotFound = () => {
    return (
        <div className="notFound">
            <h1>404 - Page non trouvé</h1>
            <p>Désolé l'URL recherché n'a pas été trouvé sur le serveur.</p>
        </div>
    );
};

export default NotFound;