import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { vehiculeService } from "../../_services/vehicule.service";
import { alerteService } from "../../_services/alerte.service";
import { pleinService } from "../../_services/plein.service";

import NavBar from "../../components/NavBar";

const VehiculeInfo = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [edit, setEdit] = useState(false);

	const [vehicule, setVehicule] = useState({});
	const [montant, setMontant] = useState({});
	const [alertes, setAlertes] = useState([]);
	
	const [plein, setPlein] = useState({}); //Composant total plein d'un vehicule code 01
	const [pleinMensuel, setPleinMensuel] = useState({}); //Composant total plein d'un vehicule code 02
	const [depenseMensuel, setDepenseMensuel] = useState({}); //Composant montant total mensuel d'un vehicule code 03
	const [depenseTotal, setDepenseTotal] = useState({}); //Composant total montant d'un vehicule code 04

	useEffect(() => {
		vehiculeService.getVehiculeInfo(id)
			.then((res) => {
				setVehicule(res.data.vehicules);
				setLoading(false);

				console.log(res.data.vehicules);
			}).catch((err) => console.log(err));

		vehiculeService.getMontantTotalMensuelVehicule(id)
			.then((res) => {
				setDepenseMensuel(res.data);
				//console.log(res.data)
			}).catch((err) => console.log(err));

		vehiculeService.getMontantFacture(id)
			.then((res) => {
				setMontant(res.data);
				console.log(res.data);
			}).catch((err) => console.log(err));

		pleinService.getMontantTotalPleinMensuel(id)
			.then((res) => {
				setPleinMensuel(res.data);
				setDepenseTotal(res.data);
				//console.log(res.data)
			}).catch((err) => console.log(err));

		pleinService.getMontantTotalPlein(id)
			.then((res) => {
				setPlein(res.data);
				//console.log(res.data)
			}).catch((err) => console.log(err));

		alerteService.getVehiculeAlerte(id)
			.then((res) => {
				setAlertes(res.data.alertes);
				console.log(res.data);
			}).catch((err) => console.log(err));
	}, [id]);

	const handleDelete = (id) => {
		if (window.confirm("Etes-vous sûr de vouloir supprimer le véhicule ?")) {
			vehiculeService.postDeleteVehicule(id)
				.then((res) => {
					console.log(res);

					setVehicule(vehicule.filter((vehicule) => vehicule.id !== id));
					navigate("/vehicule/list");
				}).catch((error) => console.log(error.message));
		}
	};

	const onChange = (e) => {
		setVehicule({
			...vehicule,
			[e.target.name]: e.target.value,
		});
	};

	const onSubmit = () => {
		const data = {
			plaque: vehicule.plaque,
			marque: vehicule.marque,
			model: vehicule.model,
			annee: vehicule.annee,
			kilometrage: vehicule.kilometrage,
			ad_blue: vehicule.ad_blue,
		};

		setEdit(false);
		vehiculeService.postEditVehicule(data, vehicule.id).catch((error) => console.log(error.message));
	};

	if (loading) {
		return (
			<div className="contain-loader">
				<div className="loader">
					<div className="📦"></div>
					<div className="📦"></div>
					<div className="📦"></div>
					<div className="📦"></div>
					<div className="📦"></div>
				</div>
			</div>
		);
	}

	return (
		<div className="global">
			<NavBar />

			<section className="grey vehiculeinfo-container">
				<h2>Informations <span>{vehicule.marque} {vehicule.model}</span></h2>

				<div className="vehiculeinfo-container__content-container">
					<div className="vehiculeinfo-container__content">						
						<form>
							<div className="vehiculeinfo-container__content-buttons">
								<div className="button" onClick={() => {
									(!edit) ? setEdit(true) : setEdit(false)
									if(edit) onSubmit();
								}}>
									<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA4UlEQVR4nO2YQQqDMBBFcyeF9iCFnsMTxDt6jhZ3LXTxyoBCGoxSKJ0ZmLd1Fu//JBhNKQgCMwADcAcmoE+eAEY+mYFzcirvJwRtefsh2JZ/NkKckgP5WUR3nvXm5dP+zGR5z+dqTlbiUc3cLB/YF3At5GVFagar8mWI3JAfrcmLZF6kjxjNHljgchAim5VfWVbCV/PFXLdc4Fw234X8L4nmtYjmtYjmtYjmtSDesEoQzStBNK8IXu/zKxtStXzr14feZ2BJbeWm+aMALuQFvsOWvOBaXnAtL7iWD4IgSP/iDUXFRdAlrPH6AAAAAElFTkSuQmCC" onDragStart={(e) => { e.preventDefault() }} />
									<p>{(edit) ? "Sauvegarder" : "Éditer"}</p>
								</div>

								<div className="button" onClick={() => handleDelete(vehicule.id)}>
									<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRElEQVR4nO2WQW7CMBBFRyLdwQm6rXqWot6k9BBd9iCwQIFLQeiqlQoc4FVGRqqi2EmQYzvtvGUysf7T2PGIKIoSDOCNdt5lxOHjSwDPQEV89sA8hIBZKBU7FQDmibqwA55CHANFUeTyO42KhEYFeiLagRpERkLzHwQOwKlD3anLdCuRBV5szQzYeOrMu6mtXeQiUNXqCmDdUGeeFbXaQw4CZkvMWiSawptunXMQMJQNAa8Sa8c731ZLcohdQV1iXlIIXDtx51ljAqy6LCSJBJwSfcLnIFA4BJa5C5RN4W+RkAQCoz7E5Q2/UfMNuVxkU0f4UVxkVUv47EcJw6LHMHcZO4DX3A7xh29L/OJsa72kEAiKqEAN7UBPJDTAkXh8DyGwjShQDiHwCHxGCP8FPAQXsBL39kYdYjsd7Xw0THhF+aP8AAGTAo+hwi7UAAAAAElFTkSuQmCC" onDragStart={(e) => { e.preventDefault() }} />
									<p>Supprimer</p>
								</div>
							</div>

							<div className="vehiculeinfo-container__content-inputs">
								<div className="input-container">
									<label htmlFor="plaque">Plaque</label>
									<input type="text" name="plaque" value={vehicule.plaque} disabled={(edit) ? false : true} onChange={onChange} />
								</div>

								<div className="input-container">
									<label htmlFor="marque">Marque</label>
									<input type="text" name="marque" value={vehicule.marque} disabled={(edit) ? false : true} onChange={onChange} />
								</div>

								<div className="input-container">
									<label htmlFor="model">Modèle</label>
									<input type="text" name="model" value={vehicule.model} disabled={(edit) ? false : true} onChange={onChange} />
								</div>

								<div className="input-container">
									<label htmlFor="annee">Année</label>
									<input type="text" name="annee" value={vehicule.annee} disabled={(edit) ? false : true} onChange={onChange} />
								</div>

								<div className="input-container">
									<label htmlFor="kilometrage">Kilométrage</label>
									<input type="text" name="kilometrage" value={vehicule.kilometrage} disabled={(edit) ? false : true} onChange={onChange} />
								</div>

								<div className="input-container">
									<label htmlFor="ad_blue">AdBlue</label>

									{
										(!edit) ? (
											<input type="text" name="ad_blue" value={vehicule.ad_blue ? "Oui" : "Non"} disabled={(edit) ? false : true} onChange={onChange} />
										) : (
											<input type="checkbox" name="ad_blue" value={vehicule.ad_blue} checked={vehicule.ad_blue} onChange={(e) =>
												setVehicule({
													...vehicule,
													ad_blue: e.target.checked,
												})
											} />
										)
									}
								</div>
							</div>
						</form>
					</div>

					<div className="vehiculeinfo-container__content-carview">
						<p className="vehiculeinfo-container__content-title">Véhicule :</p>
						
						<div className="vehiculeinfo-container__content-img">
							<img src="/icone/voiture3.jpg" alt="schema de voiture" className="voiture-card" onDragStart={(e) => { e.preventDefault() }} />
						</div>
					</div>
				</div>

				{/*<h2 id="vehicule-title">Entretiens</h2>*/}

				{/*<div className="vehiculeinfo-container__content-container">
					<div className="vehiculeinfo-container__content">						
						<form>
							<div className="vehiculeinfo-container__content-inputs">
								<div className="input-container">
									<label htmlFor="totalpleinmensuel">Total Pleins (Mensuel) :</label>
									<input type="text" name="totalpleinmensuel" value={`${pleinMensuel.total}€`} disabled={true} />
								</div>

								<div className="input-container">
									<label htmlFor="totalplein">Total Pleins</label>
									<input type="text" name="totalplein" value={`${plein.total}€`} disabled={true} />
								</div>

								<div className="input-container">
									<label htmlFor="totaldepensemensuel">Dépenses du mois :</label>
									<input type="text" name="totaldepensemensuel" value={`${depenseMensuel.total}€`} disabled={true} />
								</div>

								<div className="input-container">
									<label htmlFor="totaldepense">Total Dépenses</label>
									<input type="text" name="totaldepense" value={`${depenseTotal.total}€`} disabled={true} />
								</div>

								<div className="input-container">
									<label htmlFor="totaldepense">Total Dépenses</label>
									<input type="text" name="totaldepense" value={`${depenseTotal.total}€`} disabled={true} />
								</div>
							</div>
						</form>
					</div>
				</div>*/}
			</section>
		</div>
	);
};

export default VehiculeInfo;
