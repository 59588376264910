import Axios from "./caller.service";

const addPlein = (id, credentials) => {
	return Axios.post("/api/plein/plein-essence/" + id, credentials);
};

const deletePlein = (id) => {
	return Axios.delete("/api/plein/" + id);
};

const listVehiculePlein = (id) => {
	return Axios.get("/api/plein/list/" + id);
};

const getMontantTotalPlein = (id) => {
	return Axios.get("/api/plein/montant-total-pleins/" + id);
};

const getMontantTotalPleinMensuel = (id) => {
	return Axios.get("/api/plein/montant-total-pleins/mensuel/" + id);
};

export const pleinService = {
	addPlein,
	deletePlein,
	listVehiculePlein,
	getMontantTotalPlein,
	getMontantTotalPleinMensuel,
};
