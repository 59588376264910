import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import { accountService } from "../../_services/account.service";

import AuthBanner from "../../components/AuthBanner";

const Login = () => {
	const [credentials, setCredentials] = useState({
		username: "",
	});

	const navigate = useNavigate();

	const onChange = (e) => {
		setCredentials({
			...credentials,
			[e.target.name]: e.target.value,
		});
	};

	const onSubmit = (e) => {
		e.preventDefault();

		accountService.mdpOublie(credentials)
			.then((res) => {
				console.log(res);
				navigate("/auth/login");
			}).catch((err) => console.log(err.message));
	};

	return (
		<div className="auth-form">
			<AuthBanner />

			<div className="auth-form__container">
				<img src='/icone/AutoMiles.png' alt='logo' className='auth-form__logomobile' onClick={() => navigate('/')}/>

				<div className="title-container">
					<p className="title">Mot de passe oublié</p>
					<span className="subtitle">Merci de bien vouloir renseigner votre adresse e-mail, nous vous enverrons un lien de réinitialisation.</span>
				</div>				

				<form onSubmit={onSubmit}>
					<div className="form-input">
						<label htmlFor="username">Email</label>
						<input type="email" value={credentials.username} name="username" placeholder="Entrez votre email" onChange={onChange} />
					</div>

					<input type="submit" value="Envoyer" />
				</form>				

				<hr />
				
				<div className="auth-form__others">
					<p>Vous avez déjà un compte ? <Link to="/auth/login">Connectez-vous</Link></p>
				</div>
			</div>
		</div>
	);
};

export default Login;