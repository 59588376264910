import moment from "moment";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { vehiculeService } from "../../_services/vehicule.service";
import { pleinService } from "../../_services/plein.service";

import NavBar from "../../components/NavBar";

const PleinVehiculeList = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const [loading, setLoading] = useState(true);

	const [vehicule, setVehicule] = useState({});
	const [pleins, setPleins] = useState([]);

	useEffect(() => {
		vehiculeService.getVehiculeInfo(id)
			.then((res) => setVehicule(res.data.vehicules)).catch((err) => console.log(err));

		pleinService.listVehiculePlein(id)
			.then((res) => {
				console.log(res.data)
				setPleins(res.data.pleins);
				setLoading(false);

				console.log(res.data.pleins);
			}).catch((err) => console.log(err.message));
	}, []);

	const handleDelete = (id) => {
		pleinService.deletePlein(id)
			.then(() => window.location.reload())
			.catch((err) => console.log(err.message));
	};

	if (loading) {
		return (
			<div className="contain-loader">
				<div className="loader">
					<div className="📦"></div>
					<div className="📦"></div>
					<div className="📦"></div>
					<div className="📦"></div>
					<div className="📦"></div>
				</div>
			</div>
		);
	}

	return (
		<div className="global">
			<NavBar />

			<div className="grey vehiculelist-container">
				<h2>Pleins <span>{vehicule.marque} {vehicule.model}</span></h2>

				<section className="vehiculelist-container__content">
					<div className="button-container">
						<input className="button" type="button" value="+ Ajouter" onClick={() => navigate("/plein/vehicule/" + vehicule.id)} />
					</div>

					<table className="rwd-table">
						<tbody>
							<tr>								
								<th>Quantité</th>
								<th>Carburant</th>
								<th>Kilomètre</th>
								<th>Coût</th>
								<th>Date</th>
								<th></th>
							</tr>

							{pleins.map((plein, index) => (
								<tr key={index}>
									<td data-th="Quantité">{plein.quantite}</td>
									<td data-th="Carburant">{plein.carburant}</td>
									<td data-th="Kilomètre">{plein.kilometrage}</td>
									<td data-th="Coût">{plein.montant}</td>
									<td data-th="Date">{moment(pleins.created_at).format('DD/MM/YY')}</td>

									<td data-th="" className="rwd-buttons">
										<div className="button" onClick={() => handleDelete(plein.id)}>
											<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRElEQVR4nO2WQW7CMBBFRyLdwQm6rXqWot6k9BBd9iCwQIFLQeiqlQoc4FVGRqqi2EmQYzvtvGUysf7T2PGIKIoSDOCNdt5lxOHjSwDPQEV89sA8hIBZKBU7FQDmibqwA55CHANFUeTyO42KhEYFeiLagRpERkLzHwQOwKlD3anLdCuRBV5szQzYeOrMu6mtXeQiUNXqCmDdUGeeFbXaQw4CZkvMWiSawptunXMQMJQNAa8Sa8c731ZLcohdQV1iXlIIXDtx51ljAqy6LCSJBJwSfcLnIFA4BJa5C5RN4W+RkAQCoz7E5Q2/UfMNuVxkU0f4UVxkVUv47EcJw6LHMHcZO4DX3A7xh29L/OJsa72kEAiKqEAN7UBPJDTAkXh8DyGwjShQDiHwCHxGCP8FPAQXsBL39kYdYjsd7Xw0THhF+aP8AAGTAo+hwi7UAAAAAElFTkSuQmCC" onDragStart={(e) => { e.preventDefault() }} />
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</section>
			</div>
		</div>
	);
};

export default PleinVehiculeList;