import React, { useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import { NavLink, useNavigate } from 'react-router-dom';
import { alerteService } from '../_services/alerte.service';
import { roleService } from '../_services/role.service';
import { vehiculeService } from '../_services/vehicule.service';
import { pleinService } from '../_services/plein.service';
import Vehicule from './Vehicule/Vehicule';
import FactureHistorique from './Facture/FactureHistorique';
import PleinChoix from './Plein/PleinChoix';

const Home = () => {
    const [alertes, setAlertes] = useState([]);
    let navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(true);
    const [isHoveredV, setIsHoveredV] = useState(false);
    const [isHoveredF, setIsHoveredF] = useState(false);
    const [isHoveredE, setIsHoveredE] = useState(false);
    const [depenseMensuel, setDepenseMensuel] = useState({}) // code 01
    const [depense, setDepense] = useState({}) // code 02
    const [plein, setPlein] = useState({}) // code 03
    const [pleinMensuel, setPleinMensuel] = useState({}) // code 04

    const [page, setPage] = useState("");

    const handleMouseEnterV = () => {
        setIsHoveredV(true);
        setIsHovered(false);
    };
    const handleMouseLeaveV = () => {
        setIsHoveredV(false);
        setIsHovered(true);
    };
    const hoverStyleV = {
        display: isHoveredV ? 'flex' : 'none',
    };
    const handleMouseEnterF = () => {
        setIsHoveredF(true);
        setIsHovered(false);
    };
    const handleMouseLeaveF = () => {
        setIsHoveredF(false);
        setIsHovered(true);
    };
    const hoverStyleF = {
        display: isHoveredF ? 'flex' : 'none',
    };
    const handleMouseEnterE = () => {
        setIsHoveredE(true);
        setIsHovered(false);
    };
    const handleMouseLeaveE = () => {
        setIsHoveredE(false);
        setIsHovered(true);
    };
    const hoverStyleE = {
        display: isHoveredE ? 'flex' : 'none',
    };
    const hoverStyleB = {
        display: isHovered ? 'flex' : 'none',
    };

    useEffect(() => {
        roleService.getRole()
            .then(res => {
                //console.log(res)
            })
            .catch(err => {
                console.log('probleme')
                console.log('pas connecter')
            })
    }, [])
    //-------------------COMPOSANT POUR MONTANT TOTAL (FACTURE + PLEINS) MENSUEL !! CODE : 01----------
    useEffect(() => {
        vehiculeService.getDepenseTotalMensuel()
            .then(res => {
                setDepenseMensuel(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    //-------------------------------------------------------------------------------------------------
    //-------------------COMPOSANT POUR MONTANT TOTAL (FACTURE + PLEINS) !! CODE : 02------------------
    useEffect(() => {
        vehiculeService.getDepenseTotal()
            .then(res => {
                setDepense(res.data)
                //console.log(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    //-------------------------------------------------------------------------------------------------
    //-------------------COMPOSANT POUR MONTANT TOTAL PLEINS !! CODE : 03------------------------------
    useEffect(() => {
        vehiculeService.getPleinTotal()
            .then(res => {
                setPlein(res.data)
                //console.log(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    //-------------------------------------------------------------------------------------------------
    //-------------------COMPOSANT POUR MONTANT TOTAL PLEINS MENSUEL !! CODE : 04----------------------
    useEffect(() => {
        vehiculeService.getPleinTotalMensuel()
            .then(res => {
                setPleinMensuel(res.data)
                //console.log(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    //-------------------------------------------------------------------------------------------------

    const onVehiculeList = () => {
        navigate('/vehicule/list')
    }

    const onFacture = () => {
        navigate('/facture/historique')
    }

    const onPlein = () => {
        navigate('/plein/choix')
    }

    const handleNav = (id) => {
        navigate('/alerte/info/' + id)
    }

    const onAlerte = () => {
        navigate('alerte')
    }
    
    const onInfos = () => {
        navigate('./MesInfos')
    }



    // const DetermineClassCss = (Valeur) => {
    //     if (Valeur <= 100) {
    //         return 'style-css-100';
    //     } else if (Valeur <= 200 && Valeur > 100) {
    //         return 'style-css-100-200';
    //     } else {
    //         return 'style-css-plus-200';
    //     }
    // }

    useEffect(() => {
        alerteService.getMyAlertes()
            .then(res => {
                setAlertes(res.data.alertes)
                //console.log(res.data.alertes)
            })
            .catch(err => console.log(err))
    }, []);

    return (
        <div className="Home">
            <NavBar />

            <div className="grey">
                <div className='home_banner'>
                    <img src="/icone/car-banner-big.jpg" />

                    <div className='home_banner--content'>
                        <div className='home_banner--text-container'>
                            <h1>Suivez l'entretien et l'évolution de vos véhicules.</h1>
                        </div>
                    </div>
                </div>

                {/*<div className="card-container">
                    <div className="card" onClick={onVehiculeList}>
                        <h3>VÉHICULE</h3>
                    </div>

                    <div className="card2" onClick={onFacture}>
                        <h3>FACTURE</h3>
                    </div>

                    <div className="card3" onClick={onPlein}>
                        <h3>CARBURANT</h3>
                    </div>
    </div>*/}
            </div>
        </div>
    );
};

export default Home;