import React from "react";
import { useNavigate } from "react-router-dom";

const VehiculeCard = ({ vehicule, route }) => {
	const navigate = useNavigate();

	const handleNav = () => navigate(route);

	return (
		<div key={vehicule.id} className="vehicules-card" onClick={() => handleNav(vehicule.id)}>
			<div className="vehicules-card__text">
				<div>
					<p className="title">{`${vehicule.marque} ${vehicule.model}`}</p>
					<p className="subtitle">{vehicule.plaque}</p>
				</div>

				<p className="title">{vehicule.annee}</p>
			</div>

			<div className="vehicules-card__img">
				{/* remplacer par un systeme qui fetch automatiquement l'image du vehicule grace au modele et nom du vehicule */}
				<img src="/icone/voiture3.jpg" alt="schema de voiture" className="voiture-card" onDragStart={(e) => { e.preventDefault(); }} />
			</div>

			<div className="vehicules-card__text">
				<p className="title">{vehicule.kilometrage}Km</p>
				<span className={vehicule.ad_blue ? "adblue_pin active" : "adblue_pin"}>AdBlue</span>
			</div>
		</div>
	);
};

export default VehiculeCard;