import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import { accountService } from '../_services/account.service';
import { roleService } from '../_services/role.service';

const NavBar = () => { 
    const navigate = useNavigate();
    
    const [page, setPage] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);

    const { pathname } = useLocation();

    useEffect(() => {
        if(pathname.includes("maintenance")) setPage("Administration");
        if(pathname.length === 1) setPage("Dashboard");
        if(pathname.includes("vehicule")) setPage("Véhicules");
        if(pathname.includes("facture")) setPage("Factures");
        if(pathname.includes("plein")) setPage("Carburant");
    }, [pathname, page]);

    useEffect(() => {
        (async () => {
            const role = await roleService.getRole().catch(error => console.log(error));
            if(role && role.data.roles) setIsAdmin(role === '1');
        })();
        
        /*
        roleService.getRole()
            .then(response => {
                const role = response.data.roles;
                setIsAdmin(role === '1');
            })
            .catch(error => {
                // Gérer l'erreur de récupération du rôle
                console.log(error);
            });
        */
    }, []);

    const handleLogout = () => {
        accountService.logout();
        navigate('/auth/login');
    };

    return (
        <div className="navbar">
            <img className='logopc' src='/icone/AutoMiles.png' alt='logo' onClick={() => navigate('/')} onDragStart={(e) => { e.preventDefault() }} />
            <img className="logomobile" src="/icone/logo.png"  onClick={() => navigate('/')} onDragStart={(e) => { e.preventDefault() }} />

            <div className='navbar-container'>
                <section>
                    {isAdmin && (
                        <div className={(page === "Administration") ? "nav-button__container active" : "nav-button__container"} onClick={() => navigate("/maintenance/list")}>
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAADT0lEQVR4nO2aPWgUQRTHH2owkhgVLAQLiYWNggETv0sDJmIRLBQbwVYQe9MYYgJ2RkVE1FNQIWJhqaJoCuMXFqIigpDELwwoJEpMNPiTIe/gWO72Zvdmjlm8Hyzc3e7M3H/37Zv33oxIjRo1atT4jwEWA13AOeAJMAL81OMdcBHYJlkHaAC6gQnsyAF1kkWANcB7knNFsgawDhgnPd+Bh8AeCR1gEfAKd5yUkAF6cM9BCRFgIfDVg+AvxnIkNIC9+CO89xk441HweQkN4LlHwU8lNIBPHgWPS2gAvzwKnpbQAH54FDwpoQG88Sj4rYQGcMGj4JyEBrDbo+AuCTRp+OtBrOlzrYQGcA9/3JHQAEY9Ch6R0ADuehR8W0IDOOJR8GEJDaAeeOBB7H2TekqoAIMOxQ5K6AD7HQreJ6EDLABeOhD7OjNlW6C9wiDEtN0hWQLorUBwj2QNYB5wOoXYU6atZBUSIlmHmuB4EtzIJVpWMkeThALJGC3RR7OuLm7Q73VAf0G7/vwUBrQBl4HV1RZq/uTjBGL/AFtL9HVNr5nScnCxJdhJPZcvJl6ttuChhE+32/EUV52pDVgRMTcbPpdaP1JLMetLSTFtmn2JXKox9A1gptL0z7x/asY9KcUWiu4FrlcsHlgJHDLlF+C3xeDfgGWWfRunU4pps24MbNJtFQ36eUDPuat8Mhc9dQK3gNkUd/yE5Tht6qCifATWx7Rr0WuiTOU9fBKx24EXVIbxoKssx4t6+Ok4sRHR0Sf9KKnYoymfaKqNKzrPTqTd/qDxeCET1qmmh20MYxb7uop5+Y2R63ap+X4AOiLnNhdp3182IgN2eiiuny0zZrF319AYuc4ILXoT9aYVYypu4PlmEQv3dIYquN2DWLOkWl9GcBPQZ2HSHSp6zFiihUkfjzVpT3s2bsaJLeO0BmzapnZazG0Edc2BBH96ONLWTDUtltNSNNobthnQ9Z4rM60ttxTbGhN4tKQMPFrLDRoXpqVhyEasjm1y31LMqMma97RRjy36W1wcf0lCRTMkV8nDMe3LT+bkmiDTQ9+kjPayV9vOY8o1FiUe89uzAmdX3RKPS7QgkMt7Wp2n+yJBRV2Bh89l1pylBBqRmadpDusy7T9+3tP22Py6ngAAAABJRU5ErkJggg==" onDragStart={(e) => { e.preventDefault() }} />
                            <p>Administration</p>
                        </div>
                    )}

                    <div className={(page === "Dashboard") ? "nav-button__container active" : "nav-button__container"} onClick={() => navigate("/")}>
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAACXBIWXMAAAsTAAALEwEAmpwYAAACAElEQVR4nO3ZvU7DMBSG4UhAK0H5H2lvBe6CiZmBwj105B6YWdkAMfFToIBAHWCFlY0BZvQiq6kalTo5jp3YlfJJlSolzTmPT2S1TRRVqVIl6ADzQBvYU+8NPrcFvAFf8esV2Cy22+ym1oAXRlHv14Sf/eB/3ovvWt/Q6hjGCIUmkY8AK8CTrimgD6xPBYhsjAgVBIjBbfY8oY9u/BLfft5BwDLwOKGHW2ARWAAupZPyCiIDkzhPjPIGQogxRXkBIZ9MBzg0QZUOYoB5EGKGEaNKBaGfjNrJGhrMMJ3E8UbK7lcOKOdkxiOZVPEgRxgrVOQY03OEyY2KHGGWCsDkQkWBY4xRkQPMfc7dzDSS3Y9kXVOMWqlrip1MnkndTfoGEiKmGBSDi1x5wrhFEQbGDYqwMHYo9Cf7xAxjhgLmgJuStuYit/QuUFcn7ASOMUHtqoPbU4CRotrqwAxwBHwCxwFjdKgT4Be4SP2r2QJzCjS1Fx5dvwmc2aJUgNkoLZaTycQk6rQs6nSkRaxuMykmUc/Jll5UgbJB+C9QgdLjf8WqCaXH/4pVE0qP/xWrJpQe/ytWTSg9khX7wS5lfTlV+ZYUmfTcxyTqJ0FLiDm3rNWTgA6YnuxLQPX4sWDo6QO1TFCM2ggc1Vc9ijAJVE2NNH6UYrtRuIjqQT0JUT1pJ/MHNtRxBxUDKpIAAAAASUVORK5CYII=" onDragStart={(e) => { e.preventDefault() }} />
                        <p>Dashboard</p>
                    </div>

                    <div className={(page === "Véhicules") ? "nav-button__container active" : "nav-button__container"} onClick={() => navigate("/vehicule/list")}>
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAACyUlEQVR4nO2ZS2gUQRCGR8SgEoNGTUgIKCp68BVU8KAIPlAvCYJHvYgXz258HDwoKEZQUBFBPAVEL3rQm6+LXhR8BEH0IgQ1vo0SMPgin5TUwtjb685sZnaS3fqgL9NVXfXP1nb3dAeBYRiGYRiGYRipA0wEtgAngCvATeAO8AB4CLzQ9g4YAIb4l1/6PN/6gKfAfR3rIrAfWDQahB4APlE5bgCzsxA7D3hCNnwF1lRS7Fygn2yR0l9QqTLu9SQwCJwBdgAbgNXAcmAJMEfKEJjmOumYE7Qv38R+MbBSx9qp/2WXR0Bd2oL3eQLLRNUY0b9AcIzYez2xj5QtphTAZM8EdRkYL28aOAa80XLv9r39YoJj+F9yhvgNrArSANjlBPsCNGufJOjSHUNwVH8p+VeOnSx59UmLHQc8cwIdDvXLL+PyNobgSP5qux4YdmzPJy24wwnwE2gL9ftm7dcxBEfyD9mf9NhvTVLwbWfwHqffV5JHYwiO5B+yn6S7sTDvgaYkxLZ7kml3bOo06f4RTFol/Z2xlmmlhbmahOAez973A6OXs7q3nxVXaD2wG/jB2OUzcAs4DmwDFsoy6hPbqDuZamQIuAfsAabkBZ+mNnj8d5cIvKR2OBV4FvZqpq9g+ahyhmtNMCa42gmoMQJqjCCG7XcgB7Roy+mzrCgrn0C+RSMGyHm2pbJly4py8hkQo3MRA7R4ArSSHeXkc02MmoHnKQVIk7j5DAJL84bT5dxKD8mK0TXKSjpOPneBFQWfiurUBmwHrjtOMiF06Vts1cGznrRK5XMBmO8V6hE+U685oiKT39QgJWTsGBOs8FEqN26QzoinIGKzKS2xoXw2R8xHDu07yg2yUW/x/ne0sjZxdcXzWacxiyFV2TnSIE3AIb1c+6atV++AGhJTEz2fBr37CucjJxoHgRmVzscwDMMwDMMwDCMY8/wBE3IuVCN6vy0AAAAASUVORK5CYII=" onDragStart={(e) => { e.preventDefault() }} />
                        <p>Véhicules</p>
                    </div>

                    <div className={(page === "Factures") ? "nav-button__container active" : "nav-button__container"} onClick={() => navigate("/facture/historique")}>
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAACBUlEQVR4nO2bTStFQRzG5wOgbIRsvLOwoPANbCxsfASkrKyk7uKuvMTOCitXWVkoWRErQr4AWy8lQlGSbj+dmpvT6Vwd7px7/+f4/2o258x0n3lm5jlvc41RFOU3ADVABrgA3igv3u9dAXvAFNBkygkwCNwhhw9gBagrR+e7gBdk4g3KYNwGHCKDdWASOAscfweG4up8N3LI+nSNAo++c09AWxwGTCDQAKutHbjxnT+Ow4AMcpgL0ddnA7HAiGsDsshht4jGRV+dozQb8AkMhGhstOcKdWrTakAh7Ma8Tgd0nvDNcJoNiMLEfzcg898NyKoBrkBnALoESB6aAUYzwBFoBqAZQPLQDDCaAY5AM4CoGXAJNPuMa7EfMoKcAvV/GIge4F5yBiyFtF0OqTddgpYt6TOg1deutcgM8N7pN/xBRy/wINkASagBRmeAI9AlQNQMyAM5YMGWnD0WxPuKs+arF7VsS8+AjZC2nglB5kvQciDZgFxI282QegslaDmSbEDednjGls0flsCqr16UMgvsRNRRMQMkoQYYnQGOQJcA+jhMNPRxGH0cFoNeBYxeBRyBXgbRW2GSh2aA0QxI52bpiuwTHCd5jLn+u0zS6HBmgAewT3I4NK4BOoFn5PPsfPQLePv0gVvkcg30mzgBqu0b2nPgtdI9thrOraaqWDuvKCZ1fAE6qQqMbiNIoQAAAABJRU5ErkJggg==" onDragStart={(e) => { e.preventDefault() }} />
                        <p>Factures</p>
                    </div>

                    <div className={(page === "Carburant") ? "nav-button__container active" : "nav-button__container"} onClick={() => navigate("/plein/choix")}>
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABlklEQVR4nO2Wv0sDMRSAnxQXcXAQN3UV6ujuKC7iIO3i5OKiuLrVUXB0cfDHpoMIOrrpf6I46aCgokOLn8SmEMq1ufaSuxzmm5LwXvK+e+GISKQk4BmJAhZiByyIb1wfGAVC7gBQzbr/QAe63A/YA1rAeuai0xzocj/axXdwK+FbAJgHml1ral73IuAaaZ+xliDhphN5CHiVyEtAoa5NQki265SngKJHWFNKLkAUyLEDT2nifF6hz4wCy0kSeQl8AbPA5bACHYoSaOicEeCobAKnQMXIqwDXZRHYV189IXcSeAlZoAVsWfK3QxbYSJE/DryHKHA7wB43IQrULXmjxrgRosCCJa9mjDdDFFjpk6P+PhfGfCdEgbM+OTXgypifhCjQ7HWN9FPiTxCYAj5CFFA8A4td8TNabheYAO5xgHgSUPwAh0b8uV4/Bh5cFO9T4Fs/I8Z07JIWco54ELgDpo24OeDNR/G+BNTbf1XHVIFH8iwsLSkecwfA6zDFK8Q3eEaigIXYAQviGgpGokDByL/vQETy4RdBJG0QT62C0gAAAABJRU5ErkJggg==" onDragStart={(e) => { e.preventDefault() }} />
                        <p>Carburant</p>
                    </div>
                </section>

                <section className="navbar-others">
                    <p>Autres</p>

                    <div className="nav-button__container">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACWElEQVR4nO2Zy2oUQRSGy4wmCxOFLIImbrIxYoQo5AW8bL0sjU+gRBLUuNa4iGjiC6jPorg1OzMxgmSngjtxDGTE8MnRCjSDk6pzquNUQ//QMBQ9/2Wm+vTp087VqFGjNADXgC90x2fgqssRQB/wlTDknD6XG4BTxGPC5QbghiLAjMsNwLIiwFOXC4ATwDOgpQjQ8t8Z+x8GR4HHwANgqrB+CHgEtLGjDSwKV4H3rF8TzdFU8/3Aeofoey+wSnlY9T/GRse6aPenBLhP77FgNT8CfOu1e+A7cNwS4CX54IXW/Dlgh3ywA0xrArxOEHsHzAOTwGF/TPq1tQTeV7HmjxoFtoFbe/U5QAOYTSi9QzEBDvyjdMaYP6/4hy8YQqyJt1iBceCDgvxmrPmCxm0Fv3gZ1woMA28i97y6PfbbqRnBLx6Gtfy7IgPAx4DAvIn8L/+dALdoD1j5d0U2AyKnE7jPBLg3k8x7ka2AyGAC91CAe6uMAD+SS1t37iMB7lYZAaq7hajyRUx8GZUbTMNYRtf3pYwabmSzhgBz+3IjM7YS0hZcVJi/BPxUajSjWomEZq7t24NGYNvMGczrKp60rtjR9BenVJhBf8jnu4Z/Vt9OFyYDv6jqA41AHuPIB89dxR/qj6kD+BALvXYP3DOZ32OwteGHUGUOtt76YZkMzToLgn2wVRgtLgEP5eLuGC0uJo4Wt/3I8mCBd8qvLZnmQYaAY8bh7kry7LNMyMhcEeCJyw3AjCLAdZcbgAlFgJMuN1D1l3wC4Ip/ldoNn4DLf06uUaOGKwO/AfKVvmEdXLXsAAAAAElFTkSuQmCC" onDragStart={(e) => { e.preventDefault() }} />
                        <p>Paramètres</p>
                    </div>

                    <div className="nav-button__container" onClick={() => handleLogout()}>
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAC7UlEQVR4nO3dO2sUYRTG8SNeUQTveEUbW7FV4zVJo50Wau0FxEJWBTWIdTqxNNZevoR3G6NfQHsvhahRFNHgX16cQIpMJomsc87J84Ntd5+zz+zs7szsu2YiIiIiIiIiIiKSG7AJOA50gEst3TpVho02WwAHgGfAb/woWZ4C+y0rYC4wiH83gfmWCTAHuEccd0pmywK4SDwdywBYDYwQzxdgjSXf+t9W+9zBlm7lsd9Nku+8RQc8qhnuObDMQb7lwHBNxgcWXbWVT2SnOQHsqsn4xqIDRmuGW2BOAAtrMo5adDWDYc4QJGfawQiSM+1gBMmZdjCC5Ew7GEFyph2MIDnTDkaQnGkHAxYD24CrwAevOdMWMB6wtDpO5Dpn2gLGAJctusgFpOC9AGALcBY4Cay0bDwXAPQCX8fFeg/ssEycF/B6gmgjng6Vpy0AWNRwOrLHMvBaQAG8mqSEsmvabdE5L6AP+NVQwh6LzHMBBXAE+DlJCd/K1XwWlfcCCuDwFErotYgiFFAAh4AfDSX0WTRRCiiAgw0lfA9XQqQCplFCv83WAoB9wMuGTy/dFqeEuglmeF9bq+E9+AisM+/q0s/wvgbw5bR5V5d8hvd1BV9OmXd1yZPsgtaad3Xp//FN+IXehFsqoNvSfReIVAAZvw1HKYCpPfnxjgdFKIDMR0S9F0DmJ997AUC/Tsj4Oyk/Rqcku0kn5f2elB/RZSn/iS7McgDYDJwBTujSRJldH0Ob6PL0dn+gcSvKhpLiFQAsAbYD1/QTpRZE2VDSDkaQnGkHI0jOtIMRJGfawQiSM+1gBMmZdjCC5Jw2LVnWsiCL9vVkXrTvYc1ww2XJSAf5VlQXek3kvkUHXKjbv/J30dShFpevH2pYuDX+8sXAKuAz8XxKc36g4VXg1TlLtnz9XeK4nWr5+gKYB9zAv+slq2UF7AWeOPwLk8cpliaYKmADcKz6I522lq8vj30UWN/28yEiIiIiIiIiIiLWXX8AYnD62ayUTDoAAAAASUVORK5CYII=" onDragStart={(e) => { e.preventDefault() }} />
                        <p>Déconnexion</p>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default NavBar;