import Axios from "./caller.service";

const login = (credentials) => {
	return Axios.post("/api/login_check", credentials);
};

const utilisateurCreate = (credentials) => {
	return Axios.post("/utilisateur/nouveau", credentials);
};

const saveToken = (token) => {
	localStorage.setItem("token", token);
};

const logout = () => {
	localStorage.removeItem("token");
};

const isLogged = () => {
	let token = localStorage.getItem("token");
	return !!token;
};

const getToken = () => {
	return localStorage.getItem("token");
};

const deleteToken = () => {
	localStorage.removeItem("token");
};

const mdpOublie = (credentials) => {
	return Axios.post("/mdp/oublie", credentials);
};

const reinitialisation = (id, credentials) => {
	return Axios.put(`/reinitialisation/${id}`, credentials);
};

export const accountService = {
	login,
	utilisateurCreate,
	saveToken,
	logout,
	isLogged,
	getToken,
	deleteToken,
	mdpOublie,
	reinitialisation,
};