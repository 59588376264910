import React from 'react';
import NavBar from '../components/NavBar';

const ContactMention = () => {
    return (
        <div className="ContactMention">
            <Logo />
            <p>Ici on aura les mentions légales et les contacts</p>
        </div>
    );
};

export default ContactMention;