import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { pleinService } from "../../_services/plein.service";

import NavBar from "../../components/NavBar";

const PleinVehiculeAdd = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const [credentials, setCredentials] = useState({
		montant: "",
		quantite: "",
		carburant: "",
		kilometrage: "",
	});

	const onChange = (e) => {
		setCredentials({
			...credentials,
			[e.target.name]: e.target.value,
		});
	};

	const onSubmit = (e) => {
		e.preventDefault();

		setCredentials({
			...credentials,
			montant: credentials.montant.replace(",", "."),
			quantite: credentials.quantite.replace(",", "."),
		});

		pleinService.addPlein(id, credentials).then(() => navigate("/plein/vehicule/list/" + id)).catch((err) => console.log(err.message));
	};

	return (
		<div className="global">
			<NavBar />

			<div className="grey vehiculeadd-container">
				<h2>Ajoutez un plein</h2>

				<form>
					<div className="vehiculeadd-container__inputs">
						<div className="input-container">
							<label htmlFor="montant">Montant en Euros</label>
							<input type="text" name="montant" value={credentials.montant} onChange={onChange} />
						</div>

						<div className="input-container">
							<label htmlFor="quantite">Quantité en Litres</label>
							<input type="text" name="quantite" value={credentials.quantite} onChange={onChange} />
						</div>

						<div className="input-container">
							<label htmlFor="carburant">Type de Carburant</label>
							<input type="text" name="carburant" value={credentials.carburant} onChange={onChange} />
						</div>

						<div className="input-container">
							<label htmlFor="kilometrage">Kilométrage</label>
							<input type="text" name="kilometrage" value={credentials.kilometrage} onChange={onChange} />
						</div>
					</div>

					<input className="button" type="submit" value="Ajouter" onClick={onSubmit}	/>
				</form>
			</div>
		</div>
	);
};

export default PleinVehiculeAdd;
