import React, { useEffect, useState } from "react";

import { vehiculeService } from "../../_services/vehicule.service";

import NavBar from "../../components/NavBar";
import VehiculeCard from "../../components/VehiculeCard";

const PleinChoix = () => {
	const [loading, setLoading] = useState(true);
	const [vehicules, setVehicules] = useState([]);

	useEffect(() => {
		vehiculeService.getMyVehicule()
			.then((res) => {
				setVehicules(res.data.vehicules);
				setLoading(false);
				//console.log(res.data.vehicules)
			}).catch((err) => console.log(err));
	}, []);	

	if (loading) {
		return (
			<div className="contain-loader">
				<div className="loader">
					<div className="📦"></div>
					<div className="📦"></div>
					<div className="📦"></div>
					<div className="📦"></div>
					<div className="📦"></div>
				</div>
			</div>
		);
	}

	return (
		<div className="global">
			<NavBar />

			<div className="grey carburant-container">
				<h1>Sélectionnez un véhicule</h1>

				<div className="carburant-container__vehicules">
					{vehicules.map((vehicule, index) => <VehiculeCard key={index} vehicule={vehicule} route={"/plein/vehicule/list/" + vehicule.id} />)}
				</div>
			</div>
		</div>
	);
};

export default PleinChoix;