import React, { useEffect, useState } from "react";
import FactureNav from "../../components/FactureNav";
import { factureService } from "../../_services/facture.service";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { vehiculeService } from "../../_services/vehicule.service";
import NavBar from "../../components/NavBar";
// on gere l'affichage de la liste completes des alertes d'un utilisateur

const FactureHistorique = () => {
  const [loading, setLoading] = useState(true);
  const [isOpenMois, setOpenMois] = useState(false);
  const [isOpenTotal, setOpenTotal] = useState(false);

  const [totalFacture, setMontantTotalFacture] = useState({});
  const [depense, setDepense] = useState({}); // code 02  
  const [credentials, setCredentials] = useState({
    recherche: ''
  });
  //-------------------------------------------------------------------------------------------------
  //-------------------COMPOSANT POUR MONTANT TOTAL (FACTURE + PLEINS) !! CODE : 02------------------
  useEffect(() => {
    vehiculeService
      .getDepenseTotal()
      .then((res) => {
        setDepense(res.data);
        //console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  //-------------------------------------------------------------------------------------------------
  //-------------------COMPOSANT POUR MONTANT TOTAL PLEINS !! CODE : 03------------------------------

  //-------------------------------------------------------------------------------------------------
  //-------------------COMPOSANT POUR MONTANT TOTAL PLEINS MENSUEL !! CODE : 04----------------------

  //-------------------------------------------------------------------------------------------------

  useEffect(() => {
    vehiculeService
      .getMontantTotalFacture()
      .then((res) => {
        setMontantTotalFacture(res.data);
        setLoading(false);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //-------------------------------------------------------------------------------------------------

  let navigate = useNavigate();
  const [facture, setFactures] = useState([]);

  useEffect(() => {
    factureService
      .getHistoriqueFacture()
      .then((res) => {
        setFactures(res.data.facture);
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleEdit = (id) => {
    console.log(id);
    navigate("/facture/edit/" + id);
  };

  const handlePhoto = (id) => {
    navigate("/facture/info/" + id);
  };

  const handleDelete = (id) => {
    console.log("le bouton delete fonctionne pour l'id " + id);
    if (window.confirm("Etes-vous sûr de vouloir supprimer la facture ?")) {
      factureService
        .postDeleteFacture(id)
        .then((res) => {
          console.log(res);
          setFactures(facture.filter((vehicule) => vehicule.id !== id));
          navigate("/facture/historique");
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  const DetermineClassCss = (Valeur) => {
    if (Valeur <= 100) {
      return "style-css-100";
    } else if (Valeur <= 200 && Valeur > 100) {
      return "style-css-100-200";
    } else {
      return "style-css-plus-200";
    }
  };

  const onChange = (e) => {
    setCredentials({
        ...credentials,
        [e.target.name]: e.target.value
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    factureService.plaqueSearch(credentials.recherche)
        .then(res => {
            console.log(res.data);
            setFactures(res.data.facture);
            //setRecherche(true);
        })
        .catch(err => {
            console.log(err.message);
        });
  }

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <div className="contain-loader">
        <div className="loader">
          <div className="📦"></div>
          <div className="📦"></div>
          <div className="📦"></div>
          <div className="📦"></div>
          <div className="📦"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="global">
      {/* <FactureNav /> */}
      <NavBar />
      {/*<Foot />*/}

			<section className="grey facture-container">
      <div className="Vehicule">
					<FactureNav />
            <p>
              <img
                className="retour"
                onClick={handleBack}
                src="../../../icone/backIcon.png"
                alt="icone de retour"
              />
            </p>

            <div className="sectionDivFacture">
              <div className="factureCarte-infos">
                <h3 className="titre-infos">Mes Infos</h3>

                <div className="titre-infos--container">
                  <div className="titre-infos--sub_container">
                    <h4>Dépenses du mois : </h4>
                    <div className="container">
                      <div className={DetermineClassCss(totalFacture.total)}>
                        {totalFacture.total}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {/*<SearchBar />*/}
            <form className="search-bar" onSubmit={onSubmit}>
              <input
                type="text"
                name="recherche"
                placeholder="Rechercher par plaque d'immatriculation..."
                value={credentials.recherche}
                onChange={onChange}
              />
              <button className="searchBtn" type="submit">Rechercher</button>
            </form>

            <section className="sectionDivFacture">
              <table className="tableau-facture">
                <tbody className="tableau-facture">
                  <tr className="data-facture">
                    <td>
                      <strong>Titre</strong>
                    </td>
                    <td className="hidden-titre">
                      <strong>Garage</strong>
                    </td>
                    <td>
                      <strong>Montant</strong>
                    </td>
                    <td className="hidden-titre">
                      <strong>Kilomètre</strong>
                    </td>
                    <td className="hidden-titre">
                      <strong>Création</strong>
                    </td>
                    <td>
                      <strong>Plaque</strong>
                    </td>
                    <td className="hidden-titre">
                      <strong>Marque</strong>
                    </td>
                    <td className="hidden-titre">
                      <strong>Actions</strong>
                    </td>
                  </tr>

                {facture.map((facture, index) => (
                  <tr
                    className="hidden-data-facture"
                    key={facture.id}
                    value={facture.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F5F5F5",
                    }}
                  >
                    <td className="data-facture-tableau">
                      <div className="text-écran-plein">{facture.titre}</div>
                      <div className="text-écran-mobile">
                        {facture.titre.length > 5
                          ? facture.titre.substring(0, 10) + "..."
                          : facture.titre}
                      </div>
                    </td>
                    <td className="data-facture-tableau hidden-titre">
                      <div className="text-écran-plein">{facture.garage}</div>
                      <div className="text-écran-mobile">
                        {facture.garage.length > 5
                          ? facture.garage.substring(0, 10) + "..."
                          : facture.garage}
                      </div>
                    </td>
                    <td className="data-facture-tableau">
                      <div className="text-écran-plein">
                        {facture.montant !== null ? facture.montant : ''}
                      </div>
                      <div className="text-écran-mobile">
                        {facture.montant !== null
                          ? facture.montant.length > 5
                            ? facture.montant.substring(0, 10) + '...'
                            : facture.montant
                          : ''}
                        €
                      </div>
                    </td>
                    <td className="hidden-mobile-bouton hidden-titre">
                      <div className="text-écran-plein">{facture.kilometrage}</div>
                      <div className="text-écran-mobile">
                        {facture.kilometrage.length > 5
                          ? facture.kilometrage.substring(0, 10) + "..."
                          : facture.kilometrage}
                      </div>
                    </td>
                    <td className="hidden-mobile-bouton hidden-titre">
                      {moment(facture.created_at).format("DD/MM/YY")}
                    </td>
                    <td className="data-facture-tableau">
                      <div className="text-écran-plein">{facture.vehicule.plaque}</div>
                      <div className="text-écran-mobile">
                        {facture.vehicule.plaque.length > 5
                          ? facture.vehicule.plaque.substring(0, 10) + "..."
                          : facture.vehicule.plaque}
                      </div>
                    </td>
                    <td className="hidden-titre">{facture.vehicule.marque}</td>
                    <td className="hidden-mobile-bouton act-facture" >
                      <div className="logoDelete" onClick={() => handleEdit(facture.id)}>
                        <img src="/icone/edit_FILL0_wght400_GRAD0_opsz48.svg" alt="logo modifier" />
                      </div>
                      <div className="logoDelete" onClick={() => handlePhoto(facture.id)}>
                        <img src="/icone/addPhoto.svg" alt="ajouter photographie" />
                      </div>
                      <div className="logoDelete" onClick={() => handleDelete(facture.id)}>
                        <img src="/icone/delete_FILL0_wght400_GRAD0_opsz48.svg" alt="logo supprimé" />
                      </div>
                    </td>
                  </tr>

                  ))}
                </tbody>
              </table>
            </section>
        </div>
      </section>
    </div>
  );
};

export default FactureHistorique;
