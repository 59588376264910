import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { accountService } from "../../_services/account.service";

import OpenEye from "./icons/open_eye.svg";
import ClosedEye from "./icons/closed_eye.svg";

const Reinitialisation = () => {
	const { id } = useParams();
	let navigate = useNavigate();
	const [errorMessage, setErrorMessage] = useState(null);
	const [showPassword, setShowPassword] = useState(null);

	const [credentials, setCredentials] = useState({
		password: "",
		code: "",
		email: "",
	});
	const [confirmCredentials, setConfirmCredentials] = useState({
		passwordVerif: "",
	});

	const onChange = (e) => {
		setCredentials({
			...credentials,
			[e.target.name]: e.target.value,
		});
	};

	const confirmPassword = (e) => {
		setConfirmCredentials({
			...confirmCredentials,
			[e.target.name]: e.target.value,
		});
	};

	const onSubmit = (e) => {
		if (credentials.password === confirmCredentials.passwordVerif) {
			e.preventDefault();
			accountService
				.reinitialisation(id, credentials)
				.then((res) => {
					console.log(res);
					navigate("/auth/login");
				})
				.catch((error) => {
					console.log(error.message);
					setErrorMessage(
						"Votre mot de passe ne correspond pas au premier champs ou votre code n'est pas correct."
					);
				});
		} else {
			e.preventDefault();
			setErrorMessage(
				"Votre mot de passe ne correspond pas au premier champs ou votre code n'est pas correct."
			);
		}
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return (
		<div>
			<form className="form-div" onSubmit={onSubmit}>
				<div className="input_container">
					<label className="input_label">Mot de passe</label>
					<input
						type={showPassword ? "text" : "password"}
						name="password"
						value={credentials.password}
						onChange={onChange}
						className="input_field"
					/>
					<label className="input_label">
						Vérifier votre mot de passe
					</label>
					<input
						type={showPassword ? "text" : "password"}
						name="passwordVerif"
						value={confirmCredentials.confirmPassword}
						onChange={confirmPassword}
						className="input_field"
					/>
					<span
						onClick={() =>
							togglePasswordVisibility((prevState) => !prevState)
						}
					>
						<img
							src={showPassword ? OpenEye : ClosedEye}
							alt="Cacher/Montrer l'icone"
							width={32}
						/>
					</span>
					<label className="input_label">
						Vérifier votre code unique
					</label>
					<input
						type={"text"}
						name="code"
						value={credentials.code}
						onChange={onChange}
						className="input_field"
					/>
					<label className="input_label">Email</label>
					<input
						type={"text"}
						name="email"
						value={credentials.email}
						onChange={onChange}
						className="input_field"
					/>
					<button className="form-bouton">Soumettre</button>
				</div>
			</form>

			{errorMessage && <div className="error-message">{errorMessage}</div>}
		</div>
	);
};

export default Reinitialisation;