import Axios from "./caller.service";

const postAddFacture = (id, credentials) => {
	return Axios.post("/api/facture/nouvelle/" + id, credentials);
};

const postEditFacture = (id, credentials) => {
	return Axios.post("/api/facture/" + id + "/edit", credentials);
};

const getFactureInfo = (id) => {
	return Axios.get("/api/facture/info/" + id);
};

const getHistoriqueFacture = () => {
	return Axios.get("/api/facture/historique");
};

const getVehiculeFacture = (id) => {
	return Axios.get("/api/facture/vehicule/" + id);
};

const postDeleteFacture = (id) => {
	return Axios.post("/api/facture/delete/" + id);
};

const postAjoutPhoto = (id, image) => {
	const formData = new FormData();
	formData.append("image", image);

	return Axios.post("/api/facture/photo/" + id, formData);
};

const plaqueSearch = (credentials) => {
	return Axios.post(`/api/vehicule/search/${credentials}`);
};

export const factureService = {
	postAddFacture,
	postEditFacture,
	getFactureInfo,
	getHistoriqueFacture,
	getVehiculeFacture,
	postDeleteFacture,
	postAjoutPhoto,
	plaqueSearch,
};