import React, { useState } from 'react';
import NavBar from '../../components/NavBar';
import AdminNav from '../../components/AdminNav';
import { useNavigate } from 'react-router-dom';
import { AdminService } from '../../_services/admin.service';

const MaintenanceAjout = () => {
    let navigate = useNavigate()

    const [credentials, setCredentials] = useState({
        kilometrage: "",
        contenu: ""
    })

    const onChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        AdminService.postAjoutMaintenance(credentials)
            .then(res => {
                console.log(res)
                navigate('/maintenance/list')
            })
            .catch(err => {
            console.log(err.message)
            })        
    }

    return (
        <div>
            <Logo />
            <AdminNav />
            <h1>Ajouter une Maintenance</h1>
            <form className='form-div' onSubmit={onSubmit}>
                <div className="form-field">
                    <label htmlFor="kilometrage">Kilométrage</label>
                    <input type="text" name="kilometrage" value={credentials.kilometrage} onChange={onChange}/>
                </div>
                <div className="form-field">
                    <label htmlFor="contenu">Contenu</label>
                    <input type="text" name="contenu" value={credentials.contenu} onChange={onChange}/>
                </div>
                <button className='form-bouton'>Ajouter</button>
            </form>
        </div>
    );
};

export default MaintenanceAjout;