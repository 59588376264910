import React, { useEffect, useState } from "react";
import FactureNav from "../../components/FactureNav";
import { useNavigate, useParams } from "react-router-dom";
import { factureService } from "../../_services/facture.service";
import moment from "moment";
import { alerteService } from '../../_services/alerte.service';
import { NavLink } from 'react-router-dom';

import { vehiculeService } from "../../_services/vehicule.service";
import { pleinService } from "../../_services/plein.service";
import NavBar from "../../components/NavBar";
const FactureInfo = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [alertes, setAlertes] = useState([]);

  const [facture, setFactures] = useState({});
  const [file, setFile] = useState();
  const [photos, setPhotos] = useState([]);

  //plein définition 
  const [plein, setPlein] = useState({}); 
  const [pleinMensuel, setPleinMensuel] = useState({}); 
  const [depenseMensuel, setDepenseMensuel] = useState({}); 
  const [depenseTotal, setDepenseTotal] = useState({}); 

  const handleFile = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const onSubmit = (e) => {
    e.preventDefault();
  
    if (!file) {
      //console.log('Aucun fichier sélectionné');
      return;
    }
  
    factureService
        .postAjoutPhoto(id, file)
        .then((res) => {
          //console.log(res);
          window.location.reload()
        })
        .catch((err) => {
          console.log(err.message);
        });
    };  

  useEffect(() => {
    factureService
      .getFactureInfo(id)
      .then((res) => {
        setFactures(res.data.facture);
        setLoading(false);
        setPhotos(res.data.facture.photo);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [id]);

  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    alerteService.getVehiculeAlerte(id)
        .then(res => {
            setAlertes(res.data.alertes)
            //console.log(res.data)
        })
        .catch(err => {
            console.log(err)
        })
}, [id]);
  useEffect(() => {
    vehiculeService
      .getMontantFacture(id)
      .then((res) => {
        //setMontant(res.data);
        //console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  {
    /********************Composant total plein d'un vehicule code 01**********************/
  }
  useEffect(() => {
    pleinService
      .getMontantTotalPlein(id)
      .then((res) => {
        setPlein(res.data);
        ////console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  {
    /********************-------------------------------------------**********************/
  }
  {
    /********************Composant total plein mensuel d'un vehicule code 02**************/
  }
  useEffect(() => {
    pleinService
      .getMontantTotalPleinMensuel(id)
      .then((res) => {
        setPleinMensuel(res.data);
        ////console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  {
    /********************-------------------------------------------**********************/
  }
  {
    /********************Composant montant total mensuel d'un vehicule code 03************/
  }
  useEffect(() => {
    vehiculeService
      .getMontantTotalMensuelVehicule(id)
      .then((res) => {
        setDepenseMensuel(res.data);
        ////console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  {
    /********************-------------------------------------------**********************/
  }
  {
    /********************Composant total montant d'un vehicule code 04********************/
  }
  useEffect(() => {
    pleinService
      .getMontantTotalPleinMensuel(id)
      .then((res) => {
        setDepenseTotal(res.data);
        ////console.log(res.data)
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);
  {
    /********************-------------------------------------------**********************/
  }

  if (loading) {
    return (
      <div className="contain-loader">
        <div className="loader">
          <div className="📦"></div>
          <div className="📦"></div>
          <div className="📦"></div>
          <div className="📦"></div>
          <div className="📦"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="global">
      <NavBar />
      <div className="grey facture-container">
        <div className="Vehicule">
          <FactureNav />
          {/*<Foot />*/}

          <section className="sectionDivFacture">
            <table className="tableau-facture">
              <tbody className="tableau-facture">
                <tr className="data-facture">
                  <td>
                    <strong>Titre</strong>
                  </td>
                  <td>
                    <strong>Garage</strong>
                  </td>
                  <td>
                    <strong>Montant</strong>
                  </td>
                  <td>
                    <strong><div className="text-écran-plein">Création</div><div className="text-écran-mobile">{'Création'.length > 5
                      ? 'Création'.substring(0, 2) + "..."
                      : 'Création'}</div></strong>
                  </td>
                  <td className="hidden-titre">
                    <strong>Plaque</strong>
                  </td>
                  <td className="hidden-titre">
                    <strong><div className="text-écran-plein">Modifier</div><div className="text-écran-mobile">{'Modifier'.length > 5
                      ? 'Modifier'.substring(0, 2) + "..."
                      : 'Modifier'}</div></strong>
                  </td>
                  <td>
                    <strong><div className="text-écran-plein">{'Supprimer'}</div><div className="text-écran-mobile">{'Supprimer'.length > 5
                      ? 'Supprimer'.substring(0, 2) + "..."
                      : 'Supprimer'}</div></strong>
                  </td>
                </tr>
                  <tr
                    onClick={() => handleEdit(facture.id)}
                    className="factureCarte"
                  >
                    <td className="pAlertePerso"><div className="text-écran-plein">{facture.titre}</div><div className="text-écran-mobile">{facture.titre.length > 5
                      ? facture.titre.substring(0, 5) + "..."
                      : facture.titre}</div></td>

                    <td className="pAlertePerso"> <div className="text-écran-plein">{facture.garage}</div><div className="text-écran-mobile">{facture.garage.length > 5
                      ? facture.garage.substring(0, 5) + "..."
                      : facture.garage}</div></td>
                    <td className="pAlertePerso"> <div className="text-écran-plein">{facture.montant}€</div><div className="text-écran-mobile">{facture.montant.length > 5
                      ? facture.montant.substring(0, 5) + "..."
                      : facture.montant}€</div></td>
                    <td className="pAlertePerso">
                      <div className="text-écran-plein">{moment(facture.created_at).format("DD/MM/YY")}</div><div className="text-écran-mobile">{moment(facture.created_at).format("DD/MM")}</div>
                    </td>
                    <td className="hidden-titre"><div className="text-écran-plein">{facture.vehicule.plaque}</div><div className="text-écran-mobile">{facture.vehicule.plaque.length > 5
                      ? facture.vehicule.plaque.substring(0, 5) + "..."
                      : facture.vehicule.plaque}</div></td>
                    <td
                      className="hidden-titre"
                      onClick={() => handleEdit(facture.id)}
                    >
                      <img
                        className="btn-edit-facture"
                        src="/icone/edit_FILL0_wght400_GRAD0_opsz48.svg"
                        alt="logo supprimé"
                      />
                    </td>
                    <td
                      className="logoDelete"
                      onClick={() => handleDelete(facture.id)}
                    >
                      <img
                        src="/icone/delete_FILL0_wght400_GRAD0_opsz48.svg"
                        alt="logo supprimé"
                      />
                    </td>
                  </tr>
              </tbody>
            </table>
          </section>
          <form className="form-fact" onSubmit={onSubmit}>
          <label>
            Sélectionnez une image :
            <input type="file" onChange={handleFile} accept="image/*" />
          </label>
          <button type="submit">Soumettre</button>
          </form>
          {photos.length > 0 && (
          <div>
            <p>Photos :</p>
            <div className="photo-container">
              {photos.map((photo, index) => (
                <img
                  key={index}
                  className="img-facture"
                  src={`https://apiautomiles.automiles.fr/uploads/${photo.name}`}
                  /*src={`http://localhost:8000/uploads/${photo.name}`}*/
                  alt={`Photo ${index + 1}`}
                />
              ))}
            </div>
          </div>
          )}


        

          {/*<div className="VehiculeInfo--container">
            {/********************Composant total plein d'un vehicule code 01**********************/}
          {/*  <div>
              <p>Total des pleins :</p>
              <p>{plein.total}</p>
            </div>
            {/********************Composant total plein mensuel d'un vehicule code 02**************/}
          {/*  <div>
              <p>Total des pleins ce mois-ci :</p>
              <p>{pleinMensuel.total}</p>
            </div>*/}
            {/********************Composant montant total mensuel d'un vehicule code 03**************/}
          {/*  <div>
              <p>Total des depenses ce mois-ci :</p>
              <p>{depenseMensuel.total}</p>
            </div>*/}
            {/********************Composant total montant d'un vehicule code 04**************/}
          {/*  <div>
              <p>Total des depenses :</p>
              <p>{depenseTotal.total}</p>
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default FactureInfo;
