import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { vehiculeService } from "../../_services/vehicule.service";

import NavBar from "../../components/NavBar";
import VehiculeCard from "../../components/VehiculeCard";

const Vehicule = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [vehicules, setVehicules] = useState([]);

	useEffect(() => {
		vehiculeService.getMyVehicule()
			.then((res) => {
				setVehicules(res.data.vehicules);
				setLoading(false);
				
				console.log(res.data.vehicules);
			}).catch((err) => console.log(err));
	}, []);

	if (loading) {
		return (
			<div className="contain-loader">
				<div className="loader">
					<div className="📦"></div>
					<div className="📦"></div>
					<div className="📦"></div>
					<div className="📦"></div>
					<div className="📦"></div>
				</div>
			</div>
		);
	}

	return (
		<div className="global">
			<NavBar />

			<div className="vehicules-container grey">
				<section className="vehicules-list__text-container">
					<h3>Mes Véhicules</h3>

					<div className="button-container">
						<input className="button" type="button" value="+ Ajouter" onClick={() => navigate("/vehicule/add")} />
					</div>
				</section>

				<section className="vehicules-list__cards">
					{vehicules.map((vehicule, index) => <VehiculeCard key={index} vehicule={vehicule} route={"/vehicule/info/" + vehicule.id} />)}
				</section>
			</div>
		</div>
	);
};

export default Vehicule;