import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

const Accueil = () => {

    const navigate = useNavigate()
    const nousRejoindre = () => {
        navigate('/creer')
    }

    return (
        <div id='root'>
            <div className='accueil-header'>
                <img src="/icone/AutoMiles.png" alt="logo" className="notifLogo" />
                <p>
                    <NavLink to="/auth/login" className="accueil-connexion">
                        Connexion
                    </NavLink>
                </p>
            </div>

            <div className="accueil-div-auto">
                <div className="accueil-auto-left">
                    <p className="accueil-auto-trait"></p>
                    <h2>Suivez l'entretien et l'évolution de vos véhicules.</h2>
                    <p className="accueil-texte">Avec AutoMiles, classer toutes les factures pour suivre l'entretien de votre véhicule. AutoMiles vous offre la possibilité de garder la main sur votre budget et de contrôler vos dépenses.</p> 
                </div>
                <div className="accueil-auto-right">
                </div>
            </div>
            <div className="accueil-auto-right-mobile">
                <img src='icone/multi-auto-sans-fond.png' alt='voiture Bentley' />
            </div>

            <div className="accueil-div-service">
                <div className="accueil-service-h">
                    <h3>Services</h3>
                    <p>Maintenant découvrez pourquoi AutoMiles vous sera indispensable.</p>
                    <button className='accueil-button' onClick={nousRejoindre}>Nous rejoindre</button>
                </div>
                <div className="accueil-service-1">
                    <h4>Le suivi de vos véhicules</h4>
                    <p>Vous pourrez ajouter les entretiens de chaque véhicule ainsi que leurs dépenses. Cela vous facilitera la vie en cas de vente.</p>
                </div>
                <div className="accueil-service-2">
                    <h4>Le calcul de vos dépenses</h4>
                    <p>Ajoutez simplement le montant en question et AutoMiles vous affiche la somme des dépenses du véhicule en question.</p>
                </div>
                <div className="accueil-service-3">
                    <h4>La possibilité de centraliser vos factures</h4>
                    <p>AutoMiles vous permet d'ajouter une facture à une dépense, pour cela rien de plus simple. Vous prenez une photo ou un scan de la facture et vous l'ajouter dans votre partie facture.</p>
                </div>
            </div>

            <div className="accueil-div-banniere">
                <div className="accueil-div-photo1">
                    <img src="icone/rangement.png"/>
                </div>
                <div className="accueil-div-photo2">
                    <img src="icone/calcul.png"/>
                </div>
                <div className="accueil-div-photo3">
                    <img src="icone/entretien.png"/>
                </div>
            </div>

            <div className='accueil-div-prix'>
                <div className="accueil-service-h-mobile">
                    <h3>Prix</h3>
                    <p>AutoMiles a pour but de permettre aux utilisateurs de maintenir un oeil sur leurs dépenses concernant les véhicules. Elle est donc adapté aux particuliers souhaitant fournir tout l'historique d'entretien d'un véhicule au moment de la vente mais également aux professionnels souhaitant surveiller leur flotte.</p>
                </div>
                <div className='accueil-service-1'>
                    <h4>Combien coûte nos services ?</h4>
                    <p>Le but de l'application est de vous garantir un contrôle sur vos dépenses, de ce fait elle est complètement gratuite.</p>
                    <button className='accueil-button' onClick={nousRejoindre}>Nous rejoindre</button>
                </div>
                <div className='accueil-service-2'>
                    <h4>Sommes-nous limité en nombres de véhicules ?</h4>
                    <p>La création d'un compte permet d'ajouter autant de véhicules que possible.</p>
                </div>
                <div className='accueil-service-3'>
                    <h4>Est-ce qu'il y a une application mobile ?</h4>
                    <p>Unne application mobile vous permettant de suivre vos ajout est disponible dès maintenant !!</p>
                    <button className='accueil-button'>L'application mobile</button>
                </div>
                <div className="accueil-service-h">
                    <h3>Prix</h3>
                    <p>AutoMiles a pour but de permettre aux utilisateurs de maintenir un oeil sur leurs dépenses concernant les véhicules. Elle est donc adapté aux particuliers souhaitant fournir tout l'historique d'entretien d'un véhicule au moment de la vente mais également aux professionnels souhaitant surveiller leur flotte.</p>
                </div>
            </div>

            <div className='accueil-team'>
                <div className='accueil-team-img'>
                </div>
                <div className='accueil-team-h'>
                    <h3>Notre équipe</h3>
                    <p>Nous sommes une équipe jeune et dynamique consciente des enjeux de notre époque. Notre but est de faciliter le contrôle des dépenses quotidienne.</p>
                   
                    <div className='accueil-team-text'>
                        <p>J'utilise quotidiennement l'application. J'y ajoute le montant de mes pleins d'essence, les coûts des entretiens et les photos des factures. Cela me permets de ne plus courir après les archives. Lorsque j'ai vendu ma moto, l'application m'a permit de sortir tout l'historique en deux clics ! Merci AutoMiles !</p>
                        <div className='accueil-team-reseau'>
                            <img src='icone/profil.jpg' alt="photo de profil"/>
                            <div className='accueil-team-reseau-info'>
                                <h4>Siattom 234</h4>
                                <p>Utilisateur d'AutoMiles depuis 1 an.</p>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>

            <div className='accueil-footer'>
                <div className='accueil-footer-logo'>
                    <img src='/icone/AutoMiles.png' alt="logo" />
                    <p>© IOT Start 2023. - Tous droits réservés.</p>
                    <p></p>
                </div>
                <div className='accueil-footer-text'>
                    <h4>Contact</h4>
                    <p>102 rue Jules Verne</p>
                    <p>72300 Sablé sur sarthe</p>
                    <p>service.technique.iot@gmail.com</p>
                </div>
                <div className='accueil-footer-text'>
                    <h4>Liens</h4>
                    <p>RGPD</p>
                    <p>Mentions légales</p>
                </div>
            </div>

        </div>
    );
};

export default Accueil;