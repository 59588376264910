import React, { useEffect, useState } from 'react';
import FactureNav from '../../components/FactureNav';
import { useNavigate, useParams } from 'react-router-dom';
import { factureService } from '../../_services/facture.service';
import NavBar from '../../components/NavBar';
// on gere le fomulaire de modification d'une facture

const FactureEdit = () => {
    const { id } = useParams();
  console.log(id)
  const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [credentials, setCredentials] = useState({
        titre: "",
        garage: "",
        montant: ""
    })

    const [placeTitre, setPlaceTitre] = useState("");
    const [placeGarage, setPlaceGarage] = useState("");
    const [placeMontant, setPlaceMontant] = useState("");
    const [placeKilometrage, setPlaceKilometrage] = useState("");

    useEffect(() => {
        factureService.getFactureInfo(id)
            .then((res) => {
                console.log(res.data)
                setCredentials(res.data);
                setPlaceTitre(res.data.facture.titre);
                setPlaceGarage(res.data.facture.garage);
                setPlaceMontant(res.data.facture.montant);
                setPlaceKilometrage(res.data.facture.kilometrage);

                setLoading(false);
            })
            .catch(err => {
                console.log(err.message);
            });
    }, [id]);

    const onChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const montantInt = parseInt(credentials.montant)
        setCredentials({
            ...credentials,
            montant: montantInt
        })
        factureService.postEditFacture(id, credentials)
            .then((res) => {
                console.log(res)
                navigate('/facture/')
            })
            .catch((err) => {
                console.log(err.message)
            });
    };

    const handleBack = () => {
        navigate(-1)
    }

    if(loading){
        return  <div className='contain-loader'>
                    <div className="loader">
                      <div className="📦"></div>
                      <div className="📦"></div>
                      <div className="📦"></div>
                      <div className="📦"></div>
                      <div className="📦"></div>
                    </div>
                </div>
    }

    return (
        <div className="global">
			<section className="grey vehiculeadd-container">
                <FactureNav />
                
                <h1>Modifier une facture</h1>
                
                <form onSubmit={onSubmit}>
					<div className="vehiculeadd-container__inputs">
                        <div className="input-container">
                            <label htmlFor="titre">Entretien</label>
                            <input type="text" name="titre" placeholder={placeTitre} value={credentials.titre} onChange={onChange} />
                        </div>
                        <div className="input-container">
                            <label htmlFor="garage">Garage</label>
                            <input type="text" name="garage" placeholder={placeGarage} value={credentials.garage} onChange={onChange}  />
                        </div>
                        <div className="input-container">
                            <label htmlFor="montant">Montant en euros</label>
                            <input type="text" name="montant" placeholder={placeMontant} value={credentials.montant} onChange={onChange}  />
                        </div>
                        <div className="input-container">
                            <label htmlFor="kilometrage">kilométrage</label>
                            <input type="text" name="kilometrage" placeholder={placeKilometrage} value={credentials.kilometrage} onChange={onChange}  />
                        </div>
                        <div className='footerFacture'>
                            <button className='boutonFiltreFacture'>Ajouter</button>
                        </div>
                    </div>
                    
                </form>
            </section>
        </div>
    );
};

export default FactureEdit;