import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import AuthGuard from "./_helpers/AuthGuard";

import Accueil from "./pages/Accueil";
import About from "./pages/About";

import NotFound from "./pages/NotFound";
import AuthRouter from "./pages/Auth/AuthRouter";

import ForgetPassword from "./pages/Auth/MdpOublie";
import Reinitialisation from "./pages/Auth/Reinitialisation";

import Home from "./pages/Home";

import Vehicule from "./pages/Vehicule/Vehicule";
import VehiculeAdd from "./pages/Vehicule/VehiculeAdd";
import VehiculeInfo from "./pages/Vehicule/VehiculeInfo";

import AlertePerso from "./pages/Alerte/AlertePerso";
import AlerteStatut from "./pages/Alerte/AlerteStatut";
import AlerteStatutClos from "./pages/Alerte/AlerteStatutClos";

import FactureHome from "./pages/Facture/FactureHome";
import FactureAdd from "./pages/Facture/FactureAdd";
import FactureEdit from "./pages/Facture/FactureEdit";
import FactureVehicule from "./pages/Facture/FactureVehicule";
import FactureHistorique from "./pages/Facture/FactureHistorique";
import FactureChoix from "./pages/Facture/FactureChoix";

import Creer from "./pages/Auth/Creer";
import FactureInfo from "./pages/Facture/FactureInfo";
import ContactMention from "./pages/ContactMention";

import MaintenanceList from "./pages/admin/MaintenanceList";
import MaintenanceEdit from "./pages/admin/MaintenanceEdit";
import MaintenanceAjout from "./pages/admin/MaintenanceAjout";

import PleinChoix from "./pages/Plein/PleinChoix";
import PleinVehiculeAdd from "./pages/Plein/PleinVehiculeAdd";
import PleinVehiculeList from "./pages/Plein/PleinVehiculeList";

const App = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={
						<AuthGuard>
							<Home />
						</AuthGuard>
					}
				/>
				<Route
					path="/accueil"
					element={
						<AuthGuard>
							<Accueil />
						</AuthGuard>
					}
				/>
				<Route path="*" element={<NotFound />} />
				{/* Route pour la gestion des erreurs 404 */}
				<Route
					path="/about"
					element={
						<AuthGuard>
							<About />
						</AuthGuard>
					}
				/>
				<Route
					path="/info"
					element={
						<AuthGuard>
							<ContactMention />
						</AuthGuard>
					}
				/>
				<Route path="/creer" element={<Creer />} />
				{/* ------------------------------------------SECTION VEHICULE------------------------------------------ */}
				<Route
					path="/vehicule/info/:id"
					element={
						<AuthGuard>
							<VehiculeInfo />
						</AuthGuard>
					}
				/>
				<Route
					path="/vehicule/list"
					element={
						<AuthGuard>
							<Vehicule />
						</AuthGuard>
					}
				/>
				<Route
					path="/vehicule/add"
					element={
						<AuthGuard>
							<VehiculeAdd />
						</AuthGuard>
					}
				/>
				{/* ------------------------------------------SECTION  ALERTE------------------------------------------ */}
				<Route
					path="/alerte"
					element={
						<AuthGuard>
							<AlertePerso />
						</AuthGuard>
					}
				/>
				<Route
					path="/alerte/info/:id"
					element={
						<AuthGuard>
							<AlerteStatut />
						</AuthGuard>
					}
				/>
				<Route
					path="/alerte/statut/clos"
					element={
						<AuthGuard>
							<AlerteStatutClos />
						</AuthGuard>
					}
				/>
				{/* ------------------------------------------SECTION Maintenance------------------------------------------ */}
				<Route
					path="/maintenance/list"
					element={
						<AuthGuard>
							<MaintenanceList />
						</AuthGuard>
					}
				/>
				<Route
					path="/maintenance/edit/:id"
					element={
						<AuthGuard>
							<MaintenanceEdit />
						</AuthGuard>
					}
				/>
				<Route
					path="/maintenance/ajout"
					element={
						<AuthGuard>
							<MaintenanceAjout />
						</AuthGuard>
					}
				/>
				{/* ------------------------------------------SECTION FACTURE------------------------------------------ */}
				<Route
					path="/facture/"
					element={
						<AuthGuard>
							<FactureHome />
						</AuthGuard>
					}
				/>
				<Route
					path="/facture/add/:id"
					element={
						<AuthGuard>
							<FactureAdd />
						</AuthGuard>
					}
				/>
				<Route
					path="/facture/edit/:id"
					element={
						<AuthGuard>
							<FactureEdit />
						</AuthGuard>
					}
				/>
				<Route
					path="/facture/vehicule/:id"
					element={
						<AuthGuard>
							<FactureVehicule />
						</AuthGuard>
					}
				/>
				<Route
					path="/facture/historique"
					element={
						<AuthGuard>
							<FactureHistorique />
						</AuthGuard>
					}
				/>
				<Route
					path="/facture/vehicule"
					element={
						<AuthGuard>
							<FactureChoix />
						</AuthGuard>
					}
				/>
				<Route
					path="/facture/info/:id"
					element={
						<AuthGuard>
							<FactureInfo />
						</AuthGuard>
					}
				/>
				{/* ------------------------------------------SECTION Plein------------------------------------------ */}
				<Route
					path="/plein/choix"
					element={
						<AuthGuard>
							<PleinChoix />
						</AuthGuard>
					}
				/>
				<Route
					path="/plein/vehicule/:id"
					element={
						<AuthGuard>
							<PleinVehiculeAdd />
						</AuthGuard>
					}
				/>
				<Route
					path="/plein/vehicule/list/:id"
					element={
						<AuthGuard>
							<PleinVehiculeList />
						</AuthGuard>
					}
				/>
				<Route path="/auth/*" element={<AuthRouter />} />
				{/* possibilité de faire une route sur * pour gérer les erreurs */}
				<Route path="*" element={<Home />} />
				
				<Route path="/reinitialisation" element={<ForgetPassword />} />
				<Route path="/reinitialise/:id" element={<Reinitialisation />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;