import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import { accountService } from "../../_services/account.service";

import AuthBanner from "../../components/AuthBanner";

const Creer = () => {
	let navigate = useNavigate();

	const [credentials, setCredentials] = useState({
		nom: "",
		prenom: "",
		email: "",
		password: "",
	});

	const onChange = (e) => {
		setCredentials({
			...credentials,
			[e.target.name]: e.target.value,
		});
	};

	const onSubmit = (e) => {
		e.preventDefault();
		accountService
			.utilisateurCreate(credentials)
			.then((res) => {
				console.log(res);
				navigate("/auth/login");
			})
			.catch((err) => {
				console.log(err.message);
			});
	};

	return (
		<div className="auth-form">
			<AuthBanner />

			<div className="auth-form__container">
				<img src='/icone/AutoMiles.png' alt='logo' className='auth-form__logomobile' onClick={() => navigate('/')}/>

				<div className="title-container">
					<p className="title">Créer votre compte</p>
					<span className="subtitle">Inscrivez-vous dès maintenant et bénéficiez d'un accès complet à notre application.</span>
				</div>				

				<form onSubmit={onSubmit}>
					<div className="form-input">
						<label htmlFor="username">Nom</label>
						<input type="text" value={credentials.nom} name="nom" placeholder="Entrez votre nom" onChange={onChange} />
					</div>

					<div className="form-input">
						<label htmlFor="username">Prénom</label>
						<input type="text" value={credentials.prenom} name="prenom" placeholder="Entrez votre prénom" onChange={onChange} />
					</div>

					<div className="form-input">
						<label htmlFor="username">Email</label>
						<input type="text" value={credentials.email} name="email" placeholder="Entrez votre email" onChange={onChange} />
					</div>

					<div className="form-input">
						<label htmlFor="password">Mot de passe</label>						
						<input type="password" value={credentials.password} name="password" placeholder="Entrez votre mot de passe" onChange={onChange} />
					</div>

					<input type="submit" value="S'Inscrire" />
				</form>				

				<hr />
				
				<div className="auth-form__others">
					<p>Vous avez déjà un compte ? <Link to="/auth/login">Connectez-vous</Link></p>
				</div>
			</div>
		</div>
	);
};

export default Creer;
