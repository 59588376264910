import moment from "moment";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { factureService } from "../../_services/facture.service";

import FactureNav from "../../components/FactureNav";
import NavBar from "../../components/NavBar";

const FactureVehicule = () => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [facture, setFacture] = useState([]);
  let { id } = useParams();

  const handleEdit = (id) => {
    console.log(id);
    navigate("/facture/edit/" + id);
  };

  const handleDelete = (id) => {
    console.log("le bouton delete fonctionne pour l'id " + id);
    if (window.confirm("Etes-vous sûr de vouloir supprimer la facture ?")) {
      factureService
        .postDeleteFacture(id)
        .then((res) => {
          console.log(res);
          setFacture(facture.filter((vehicule) => vehicule.id !== id));
          navigate("/facture/historique");
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    factureService
      .getVehiculeFacture(id)
      .then((res) => {
        setFacture(res.data.facture);
        setLoading(false);
        console.log(res.data.facture);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [id]);

  if (loading) {
    return (
      <div className="contain-loader">
        <div className="loader">
          <div className="📦"></div>
          <div className="📦"></div>
          <div className="📦"></div>
          <div className="📦"></div>
          <div className="📦"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="global">
      <NavBar />

      <section className="grey facture-container">
        <div className="Vehicule">
          <FactureNav />
          {/*<p>
            <img
              className="retour"
              onClick={handleBack}
              src="../../../icone/backIcon.png"
              alt="icone de retour"
            />
          </p>*/}
          <h1>Factures</h1>
          <section className="sectionDivFacture">
            <table className="tableau-facture">
              <tbody className="tableau-facture">
                <tr className="data-facture">
                  <td>
                    <strong>Titre</strong>
                  </td>
                  <td>
                    <strong>Garage</strong>
                  </td>
                  <td>
                    <strong>Montant</strong>
                  </td>
                  <td>
                    <strong><div className="text-écran-plein">Création</div><div className="text-écran-mobile">{'Création'.length > 5
                      ? 'Création'.substring(0, 2) + "..."
                      : 'Création'}</div></strong>
                  </td>
                  <td className="hidden-titre">
                    <strong>Plaque</strong>
                  </td>
                  <td className="hidden-titre">
                    <strong><div className="text-écran-plein">Modifier</div><div className="text-écran-mobile">{'Modifier'.length > 5
                      ? 'Modifier'.substring(0, 2) + "..."
                      : 'Modifier'}</div></strong>
                  </td>
                  <td>
                    <strong><div className="text-écran-plein">{'Supprimer'}</div><div className="text-écran-mobile">{'Supprimer'.length > 5
                      ? 'Supprimer'.substring(0, 2) + "..."
                      : 'Supprimer'}</div></strong>
                  </td>
                </tr>
                {facture.map((facture, index) => (
                  <tr
                    onClick={() => handleEdit(facture.id)}
                    key={facture.id}
                    className="factureCarte"
                    style={{
                      backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F5F5F5",
                    }}
                  >
                    <td className="pAlertePerso"><div className="text-écran-plein">{facture.titre}</div><div className="text-écran-mobile">{facture.titre.length > 5
                      ? facture.titre.substring(0, 5) + "..."
                      : facture.titre}</div></td>

                    <td className="pAlertePerso"> <div className="text-écran-plein">{facture.garage}</div><div className="text-écran-mobile">{facture.garage.length > 5
                      ? facture.garage.substring(0, 5) + "..."
                      : facture.garage}</div></td>
                    <td className="pAlertePerso"> <div className="text-écran-plein">{facture.montant}€</div><div className="text-écran-mobile">{facture.montant.length > 5
                      ? facture.montant.substring(0, 5) + "..."
                      : facture.montant}€</div></td>
                    <td className="pAlertePerso">
                      <div className="text-écran-plein">{moment(facture.created_at).format("DD/MM/YY")}</div><div className="text-écran-mobile">{moment(facture.created_at).format("DD/MM")}</div>
                    </td>
                    <td className="hidden-titre"><div className="text-écran-plein">{facture.vehicule.plaque}</div><div className="text-écran-mobile">{facture.vehicule.plaque.length > 5
                      ? facture.vehicule.plaque.substring(0, 5) + "..."
                      : facture.vehicule.plaque}</div></td>
                    <td
                      className="hidden-titre"
                      onClick={() => handleEdit(facture.id)}
                    >
                      <img
                        className="btn-edit-facture"
                        src="/icone/edit_FILL0_wght400_GRAD0_opsz48.svg"
                        alt="logo supprimé"
                      />
                    </td>
                    <td
                      className="logoDelete"
                      onClick={() => handleDelete(facture.id)}
                    >
                      <img
                        src="/icone/delete_FILL0_wght400_GRAD0_opsz48.svg"
                        alt="logo supprimé"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </div>
      </section>
    </div>
  );
};

export default FactureVehicule;
